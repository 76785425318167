import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { StringAttribute, HTMLCustomElement } from '../../../../lib/index';
import { ReplaceBrokenImage, replaceBrokenImage } from './replace-broken-image';
import { Subscription } from 'rxjs';

export interface VcePluginReplaceBrokenImage extends HTMLElement, IVcePlugin {}
export function createVcePluginReplaceBrokenImage(
  replaceBrokenImage: ReplaceBrokenImage,
): { new (): VcePluginReplaceBrokenImage } {
  class VcePluginReplaceBrokenImageClass extends HTMLCustomElement implements VcePluginReplaceBrokenImage {
    @StringAttribute('placeholder') placeholder: string;
    private imageErrorSubscription: Subscription;

    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }

    renderedCallback(iframe: HTMLIFrameElement): void {
      if (this.imageErrorSubscription) {
        this.imageErrorSubscription.unsubscribe();
      }
      this.imageErrorSubscription = replaceBrokenImage({ placeholder: this.placeholder }, iframe.contentDocument!);
    }
  }
  return VcePluginReplaceBrokenImageClass;
}

CustomElement('vce-plugin-replace-broken-image')(createVcePluginReplaceBrokenImage(replaceBrokenImage));
