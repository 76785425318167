import { Locale } from '../interface';
import { prop, toLower, sortBy, compose } from 'ramda';

const sortByName = sortBy(
  compose(
    toLower,
    prop('name'),
  ),
);

export const locales: Locale[] = sortByName([
  { key: 'af', name: 'Afrikaans' },
  { key: 'af-ZA', name: 'Afrikaans (South Africa)' },
  { key: 'am-ET', name: 'Amharic (Ethiopia)' },
  { key: 'ar', name: 'Arabic' },
  { key: 'ar-AE', name: 'Arabic (U.A.E.)' },
  { key: 'ar-BH', name: 'Arabic (Bahrain)' },
  { key: 'ar-DZ', name: 'Arabic (Algeria)' },
  { key: 'ar-EG', name: 'Arabic (Egypt)' },
  { key: 'ar-IQ', name: 'Arabic (Iraq)' },
  { key: 'ar-JO', name: 'Arabic (Jordan)' },
  { key: 'ar-KW', name: 'Arabic (Kuwait)' },
  { key: 'ar-LB', name: 'Arabic (Lebanon)' },
  { key: 'ar-LY', name: 'Arabic (Libya)' },
  { key: 'ar-MA', name: 'Arabic (Morocco)' },
  { key: 'ar-OM', name: 'Arabic (Oman)' },
  { key: 'ar-QA', name: 'Arabic (Qatar)' },
  { key: 'ar-SA', name: 'Arabic (Saudi Arabia)' },
  { key: 'ar-SD', name: 'Arabic (Sudan)' },
  { key: 'ar-SY', name: 'Arabic (Syria)' },
  { key: 'ar-TN', name: 'Arabic (Tunisia)' },
  { key: 'ar-YE', name: 'Arabic (Yemen)' },
  { key: 'as-IN', name: 'Assamese (India)' },
  { key: 'az', name: 'Azeri' },
  { key: 'be', name: 'Belarusian' },
  { key: 'be-BY', name: 'Belarusian (Belarus)' },
  { key: 'bg', name: 'Bulgarian' },
  { key: 'bg-BG', name: 'Bulgarian (Bulgaria)' },
  { key: 'bn-BD', name: 'Bengali (Bangladesh)' },
  { key: 'bn-IN', name: 'Bengali (India)' },
  { key: 'bo-CN', name: "Tibetan (People's Republic of China)" },
  { key: 'br-FR', name: 'Breton (France)' },
  { key: 'ca', name: 'Catalan' },
  { key: 'ca-ES', name: 'Catalan (Catalan)' },
  { key: 'cs', name: 'Czech' },
  { key: 'cs-CZ', name: 'Czech (Czech Republic)' },
  { key: 'cy-GB', name: 'Welsh (United Kingdom)' },
  { key: 'da', name: 'Danish' },
  { key: 'da-DK', name: 'Danish (Denmark)' },
  { key: 'de', name: 'German' },
  { key: 'de-AT', name: 'German (Austria)' },
  { key: 'de-CH', name: 'German (Switzerland)' },
  { key: 'de-DE', name: 'German (Germany)' },
  { key: 'de-IT', name: 'German (Italy)' },
  { key: 'de-LI', name: 'German (Liechtenstein)' },
  { key: 'de-LU', name: 'German (Luxembourg)' },
  { key: 'dsb-DE', name: 'Lower Sorbian (Germany)' },
  { key: 'el', name: 'Greek' },
  { key: 'el-CY', name: 'Greek (Cyprus)' },
  { key: 'el-GR', name: 'Greek (Greece)' },
  { key: 'en', name: 'English' },
  { key: 'en-AR', name: 'English (Argentina)' },
  { key: 'en-AL', name: 'English (Albanian)' },
  { key: 'en-AO', name: 'English (Angola)' },
  { key: 'en-AT', name: 'English (Austria)' },
  { key: 'en-AU', name: 'English (Australia)' },
  { key: 'en-BA', name: 'English (Bosnia and Herzegovina)' },
  { key: 'en-BE', name: 'English (Belgium)' },
  { key: 'en-BG', name: 'English (Bulgaria)' },
  { key: 'en-BH', name: 'English (Bahrain)' },
  { key: 'en-BN', name: 'English (Brunei)' },
  { key: 'en-BR', name: 'English (Brazil)' },
  { key: 'en-BZ', name: 'English (Belize)' },
  { key: 'en-CA', name: 'English (Canada)' },
  { key: 'en-CH', name: 'English (Switzerland)' },
  { key: 'en-CL', name: 'English (Chile)' },
  { key: 'en-CY', name: 'English (Cyprus)' },
  { key: 'en-CZ', name: 'English (Czech Republic)' },
  { key: 'en-DE', name: 'English (Germany)' },
  { key: 'en-DK', name: 'English (Denmark)' },
  { key: 'en-EE', name: 'English (Estonia)' },
  { key: 'en-ES', name: 'English (Spain)' },
  { key: 'en-EU', name: 'English (Europe)' },
  { key: 'en-FI', name: 'English (Finland)' },
  { key: 'en-FR', name: 'English (France)' },
  { key: 'en-GH', name: 'English (Ghana)' },
  { key: 'en-GU', name: 'English (Guam)' },
  { key: 'en-HR', name: 'English (Croatia)' },
  { key: 'en-HU', name: 'English (Hungary)' },
  { key: 'en-ID', name: 'English (Indonesia)' },
  { key: 'en-IT', name: 'English (Italy)' },
  { key: 'en-KR', name: 'English (Korea)' },
  { key: 'en-LV', name: 'English (Latvia)' },
  { key: 'en-MO', name: 'English (Macau)' },
  { key: 'en-NA', name: 'English (Namibia)' },
  { key: 'en-NG', name: 'English (Nigeria)' },
  { key: 'en-NL', name: 'English (Netherlands)' },
  { key: 'en-GB', name: 'English (United Kingdom)' },
  { key: 'en-GR', name: 'English (Greece)' },
  { key: 'en-HK', name: 'English (Hong Kong)' },
  { key: 'en-IE', name: 'English (Ireland)' },
  { key: 'en-IL', name: 'English (Israel)' },
  { key: 'en-IN', name: 'English (India)' },
  { key: 'en-IS', name: 'English (Iceland)' },
  { key: 'en-JM', name: 'English (Jamaica)' },
  { key: 'en-JO', name: 'English (Jordan)' },
  { key: 'en-KE', name: 'English (Kenya)' },
  { key: 'en-LU', name: 'English (Luxembourg)' },
  { key: 'en-MK', name: 'English (Macedonia)' },
  { key: 'en-MT', name: 'English (Malta)' },
  { key: 'en-MY', name: 'English (Malaysia)' },
  { key: 'en-NO', name: 'English (Norway)' },
  { key: 'en-NZ', name: 'English (New Zealand)' },
  { key: 'en-OM', name: 'English (Oman)' },
  { key: 'en-PH', name: 'English (Republic of the Philippines)' },
  { key: 'en-PL', name: 'English (Poland)' },
  { key: 'en-PT', name: 'English (Portugal)' },
  { key: 'en-QA', name: 'English (Qatar)' },
  { key: 'en-RO', name: 'English (Romania)' },
  { key: 'en-SA', name: 'English (Saudi Arabia)' },
  { key: 'en-SD', name: 'English (Sudan)' },
  { key: 'en-SE', name: 'English (Sweden)' },
  { key: 'en-SK', name: 'English (Slovakia)' },
  { key: 'en-SG', name: 'English (Singapore)' },
  { key: 'en-SI', name: 'English (Slovenia)' },
  { key: 'en-SP', name: 'English (Serbia)' },
  { key: 'en-TH', name: 'English (Thailand)' },
  { key: 'en-TR', name: 'English (Turkey)' },
  { key: 'en-TT', name: 'English (Trinidad and Tobago)' },
  { key: 'en-TW', name: 'English (Taiwan)' },
  { key: 'en-AE', name: 'English (United Arab Emirates)' },
  { key: 'en-UA', name: 'English (Ukraine)' },
  { key: 'en-US', name: 'English (United States)' },
  { key: 'en-VN', name: 'English (Viet Nam)' },
  { key: 'en-ZA', name: 'English (South Africa)' },
  { key: 'en-ZW', name: 'English (Zimbabwe)' },
  { key: 'es', name: 'Spanish' },
  { key: 'es-419', name: 'Spanish (Latin American)' },
  { key: 'es-AR', name: 'Spanish (Argentina)' },
  { key: 'es-BO', name: 'Spanish (Bolivia)' },
  { key: 'es-CL', name: 'Spanish (Chile)' },
  { key: 'es-CO', name: 'Spanish (Colombia)' },
  { key: 'es-CR', name: 'Spanish (Costa Rica)' },
  { key: 'es-DO', name: 'Spanish (Dominican Republic)' },
  { key: 'es-EC', name: 'Spanish (Ecuador)' },
  { key: 'es-ES', name: 'Spanish (Spain)' },
  { key: 'es-GT', name: 'Spanish (Guatemala)' },
  { key: 'es-HN', name: 'Spanish (Honduras)' },
  { key: 'es-MX', name: 'Spanish (Mexico)' },
  { key: 'es-NI', name: 'Spanish (Nicaragua)' },
  { key: 'es-PA', name: 'Spanish (Panama)' },
  { key: 'es-PE', name: 'Spanish (Peru)' },
  { key: 'es-PR', name: 'Spanish (Puerto Rico)' },
  { key: 'es-PY', name: 'Spanish (Paraguay)' },
  { key: 'es-SV', name: 'Spanish (El Salvador)' },
  { key: 'es-US', name: 'Spanish (United States)' },
  { key: 'es-UY', name: 'Spanish (Uruguay)' },
  { key: 'es-VE', name: 'Spanish (Venezuela)' },
  { key: 'et', name: 'Estonian' },
  { key: 'et-EE', name: 'Estonian (Estonia)' },
  { key: 'eu', name: 'Basque' },
  { key: 'eu-ES', name: 'Basque (Basque)' },
  { key: 'fa', name: 'Persian' },
  { key: 'fa-IR', name: 'Persian (Iran)' },
  { key: 'fi', name: 'Finnish' },
  { key: 'fi-FI', name: 'Finnish (Finland)' },
  { key: 'fil-PH', name: 'Filipino (Philippines)' },
  { key: 'fo', name: 'Faroese' },
  { key: 'fo-FO', name: 'Faroese (Faroe Islands)' },
  { key: 'fr', name: 'French' },
  { key: 'fr-BE', name: 'French (Belgium)' },
  { key: 'fr-CA', name: 'French (Canada)' },
  { key: 'fr-CH', name: 'French (Switzerland)' },
  { key: 'fr-FR', name: 'French (France)' },
  { key: 'fr-GF', name: 'French (Guyane)' },
  { key: 'fr-GP', name: 'French (Guadeloupe)' },
  { key: 'fr-LU', name: 'French (Luxembourg)' },
  { key: 'fr-MC', name: 'French (Principality of Monaco)' },
  { key: 'fr-MQ', name: 'French (Martinique)' },
  { key: 'fr-MU', name: 'French (Mauritius)' },
  { key: 'fr-RE', name: 'French (Réunion)' },
  { key: 'fr-YT', name: 'French (Mayotte)' },
  { key: 'fy-NL', name: 'Frisian (Netherlands)' },
  { key: 'ga-IE', name: 'Irish (Ireland)' },
  { key: 'gd-GB', name: 'Scottish Gaelic (United Kingdom)' },
  { key: 'gl', name: 'Galician' },
  { key: 'gl-ES', name: 'Galician (Galician)' },
  { key: 'gsw-FR', name: 'Alsatian (France)' },
  { key: 'gu', name: 'Gujarati' },
  { key: 'gu-IN', name: 'Gujarati (India)' },
  { key: 'he', name: 'Hebrew' },
  { key: 'he-IL', name: 'Hebrew (Israel)' },
  { key: 'hi', name: 'Hindi' },
  { key: 'hi-IN', name: 'Hindi (India)' },
  { key: 'hr', name: 'Croatian' },
  { key: 'hr-BA', name: 'Croatian (Latin) (Bosnia and Herzegovina)' },
  { key: 'hr-HR', name: 'Croatian (Croatia)' },
  { key: 'hsb-DE', name: 'Upper Sorbian (Germany)' },
  { key: 'hu', name: 'Hungarian' },
  { key: 'hu-HU', name: 'Hungarian (Hungary)' },
  { key: 'hy', name: 'Armenian' },
  { key: 'hy-AM', name: 'Armenian (Armenia)' },
  { key: 'id', name: 'Indonesian' },
  { key: 'id-ID', name: 'Indonesian (Indonesia)' },
  { key: 'ig-NG', name: 'Igbo (Nigeria)' },
  { key: 'ii-CN', name: "Yi (People's Republic of China)" },
  { key: 'is', name: 'Icelandic' },
  { key: 'is-IS', name: 'Icelandic (Iceland)' },
  { key: 'it', name: 'Italian' },
  { key: 'it-CH', name: 'Italian (Switzerland)' },
  { key: 'it-IT', name: 'Italian (Italy)' },
  { key: 'ja', name: 'Japanese' },
  { key: 'ja-JP', name: 'Japanese (Japan)' },
  { key: 'ka', name: 'Georgian' },
  { key: 'ka-GE', name: 'Georgian (Georgia)' },
  { key: 'kk', name: 'Kazakh' },
  { key: 'kk-KZ', name: 'Kazakh (Kazakhstan)' },
  { key: 'kl-GL', name: 'Greenlandic (Greenland)' },
  { key: 'km-KH', name: 'Khmer (Cambodia)' },
  { key: 'kn', name: 'Kannada' },
  { key: 'kn-IN', name: 'Kannada (India)' },
  { key: 'ko', name: 'Korean' },
  { key: 'ko-KR', name: 'Korean (Korea)' },
  { key: 'kok', name: 'Konkani' },
  { key: 'kok-IN', name: 'Konkani (India)' },
  { key: 'ky', name: 'Kyrgyz' },
  { key: 'ky-KG', name: 'Kyrgyz (Kyrgyzstan)' },
  { key: 'lb-LU', name: 'Luxembourgish (Luxembourg)' },
  { key: 'lo-LA', name: 'Lao (Lao P.D.R.)' },
  { key: 'lt', name: 'Lithuanian' },
  { key: 'lt-LT', name: 'Lithuanian (Lithuania)' },
  { key: 'lv', name: 'Latvian' },
  { key: 'lv-LV', name: 'Latvian (Latvia)' },
  { key: 'mi-NZ', name: 'Maori (New Zealand)' },
  { key: 'mk', name: 'Macedonian' },
  { key: 'mk-MK', name: 'Macedonian (Former Yugoslav Republic of Macedonia)' },
  { key: 'ml-IN', name: 'Malayalam (India)' },
  { key: 'mn', name: 'Mongolian' },
  { key: 'mn-MN', name: 'Mongolian (Cyrillic) (Mongolia)' },
  { key: 'mr', name: 'Marathi' },
  { key: 'mr-IN', name: 'Marathi (India)' },
  { key: 'ms', name: 'Malay' },
  { key: 'ms-BN', name: 'Malay (Brunei Darussalam)' },
  { key: 'ms-MY', name: 'Malay (Malaysia)' },
  { key: 'mt-MT', name: 'Maltese (Malta)' },
  { key: 'no', name: 'Norwegian' },
  { key: 'no-NO', name: 'Norwegian (Norway)' },
  { key: 'nb-NO', name: 'Norwegian, Bokmål (Norway)' },
  { key: 'ne-NP', name: 'Nepali (Nepal)' },
  { key: 'nl', name: 'Dutch' },
  { key: 'nl-BE', name: 'Dutch (Belgium)' },
  { key: 'nl-NL', name: 'Dutch (Netherlands)' },
  { key: 'nn-NO', name: 'Norwegian, Nynorsk (Norway)' },
  { key: 'or-IN', name: 'Oriya (India)' },
  { key: 'pa', name: 'Punjabi' },
  { key: 'pl', name: 'Polish' },
  { key: 'pl-PL', name: 'Polish (Poland)' },
  { key: 'ps-AF', name: 'Pashto (Afghanistan)' },
  { key: 'pt', name: 'Portuguese' },
  { key: 'pt-AO', name: 'Portuguese (Angola)' },
  { key: 'pt-BR', name: 'Portuguese (Brazil)' },
  { key: 'pt-PT', name: 'Portuguese (Portugal)' },
  { key: 'rm-CH', name: 'Romansh (Switzerland)' },
  { key: 'ro', name: 'Romanian' },
  { key: 'ro-RO', name: 'Romanian (Romania)' },
  { key: 'ru', name: 'Russian' },
  { key: 'ru-RU', name: 'Russian (Russia)' },
  { key: 'rw-RW', name: 'Kinyarwanda (Rwanda)' },
  { key: 'sah-RU', name: 'Yakut (Russia)' },
  { key: 'se-FI', name: 'Sami (Northern) (Finland)' },
  { key: 'se-NO', name: 'Sami (Northern) (Norway)' },
  { key: 'se-SE', name: 'Sami (Northern) (Sweden)' },
  { key: 'sh-SP', name: 'English (Serbia)' },
  { key: 'si-LK', name: 'Sinhala (Sri Lanka)' },
  { key: 'sk', name: 'Slovak' },
  { key: 'sk-SK', name: 'Slovak (Slovakia)' },
  { key: 'sl', name: 'Slovenian' },
  { key: 'sl-SI', name: 'Slovenian (Slovenia)' },
  { key: 'smn-FI', name: 'Sami (Inari) (Finland)' },
  { key: 'sq', name: 'Albanian' },
  { key: 'sq-AL', name: 'Albanian (Albania)' },
  { key: 'sr', name: 'Serbian' },
  { key: 'sv', name: 'Swedish' },
  { key: 'sv-FI', name: 'Swedish (Finland)' },
  { key: 'sv-SE', name: 'Swedish (Sweden)' },
  { key: 'sw', name: 'Kiswahili' },
  { key: 'sw-KE', name: 'Kiswahili (Kenya)' },
  { key: 'ta', name: 'Tamil' },
  { key: 'ta-IN', name: 'Tamil (India)' },
  { key: 'te', name: 'Telugu' },
  { key: 'te-IN', name: 'Telugu (India)' },
  { key: 'th', name: 'Thai' },
  { key: 'th-TH', name: 'Thai (Thailand)' },
  { key: 'tk-TM', name: 'Turkmen (Turkmenistan)' },
  { key: 'tr', name: 'Turkish' },
  { key: 'tr-TR', name: 'Turkish (Turkey)' },
  { key: 'tt', name: 'Tatar' },
  { key: 'tt-RU', name: 'Tatar (Russia)' },
  { key: 'ug-CN', name: "Uyghur (People's Republic of China)" },
  { key: 'uk', name: 'Ukrainian' },
  { key: 'uk-UA', name: 'Ukrainian (Ukraine)' },
  { key: 'ur', name: 'Urdu' },
  { key: 'ur-PK', name: 'Urdu (Islamic Republic of Pakistan)' },
  { key: 'uz', name: 'Uzbek' },
  { key: 'vi', name: 'Vietnamese' },
  { key: 'vi-VN', name: 'Vietnamese (Vietnam)' },
  { key: 'wo-SN', name: 'Wolof (Senegal)' },
  { key: 'xh-ZA', name: 'isiXhosa (South Africa)' },
  { key: 'yo-NG', name: 'Yoruba (Nigeria)' },
  { key: 'zh', name: 'Chinese' },
  { key: 'zh-Hans', name: 'Chinese (Simplified)' },
  { key: 'zh-Hans-SG', name: 'Chinese Simplified (Singapore)' },
  { key: 'zh-Hant', name: 'Chinese (Traditional)' },
  { key: 'zh-Hant-HK', name: 'Chinese Traditional (Hong Kong)' },
  { key: 'zh-Hant-TW', name: 'Chinese Traditional (Taiwan)' },
  { key: 'zh-CN', name: "Chinese (People's Republic of China)" },
  { key: 'zh-HK', name: 'Chinese (Hong Kong S.A.R.)' },
  { key: 'zh-MO', name: 'Chinese (Macao S.A.R.)' },
  { key: 'zh-SG', name: 'Chinese (Singapore)' },
  { key: 'zh-TW', name: 'Chinese (Taiwan)' },
  { key: 'zu-ZA', name: 'isiZulu (South Africa)' },
]);
