import { TinymceEditor } from '../editable-text/models';
import { cleanUpBookmarks } from './bookmark-cleanup';
import { transformTokenHtmlToObject } from './transform-token-html-to-object';
import { v2TokenDataToToken } from '../personalization/token-data-to-token-v2';
import { V2PersonalizationState } from '../../../../lib/personalization.interface';
import { PersonalizationDialogProxyOpenDialog } from '../../../personalization/interface';

export type GetSetupPersonalizationButtonHookOptions = {
  tooltip: string;
  openDialog: PersonalizationDialogProxyOpenDialog;
  flippers: string[];
};

export type GetSetupPersonalizationButtonHook = (
  editor: TinymceEditor,
  options: GetSetupPersonalizationButtonHookOptions,
) => void;

export const getSetupPersonalizationButtonHook: GetSetupPersonalizationButtonHook = (editor, options) => {
  editor.addButton('personalization', {
    tooltip: options.tooltip,
    stateSelector: 'span[e-token]',
    onclick: () => {
      cleanUpBookmarks(editor);

      const tokenData =
        isActive(editor) && transformTokenHtmlToObject.run(editor.selection.getNode().outerHTML, options.flippers);

      const initialPersonalizationState: V2PersonalizationState | undefined = tokenData && (tokenData as any).meta;

      const insertTemplate = personalizationState => {
        const tokenHtml = v2TokenDataToToken(personalizationState);
        updateContent(editor, tokenHtml);
      };

      options.openDialog(insertTemplate, initialPersonalizationState);
    },
  });
};

function isActive(editor: TinymceEditor): boolean {
  const selectedNode = editor.selection.getNode();
  const isPersonalizationToken = selectedNode.getAttribute('e-token') === 'personalization';
  const nothingIsSelected = editor.selection.isCollapsed();
  return !nothingIsSelected && isPersonalizationToken;
}

function updateContent(editor: TinymceEditor, tokenData: string): void {
  editor.focus(false);
  editor.selection.moveToBookmark(editor.selection.getBookmark());
  editor.insertContent(tokenData);
}
